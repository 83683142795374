import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = ({ translations }) => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const aboutIsEnabled = translations?.components?.about?.enabled;
    const contactIsEnabled = translations?.components?.contact?.enabled;

    const navbarTranslations = translations?.components?.navbar?.translations || {};
    const homeTitleText = navbarTranslations['menu.title.home'];
    const aboutTitleText = navbarTranslations['menu.title.about'];
    const contactTitleText = navbarTranslations['menu.title.contact'];

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="navbar navbar-inverse navbar-fixed-top headroom">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle"
                        onClick={toggleMenu}
                    >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <Link className="navbar-brand sticky-logo" to="/">
                        <div className="table-logo">
                            <div className="column-logo">
                                <img src="images/san-partners-white.svg" alt="San & Partners Logo" className="logo-img" />
                            </div>
                            <div className="column-logo">
                                <span className="brand-name">San & </span>Partners
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={`navbar-collapse collapse ${isMenuOpen ? 'in' : ''}`}>
                    <ul className="nav navbar-nav pull-right">
                        <li className={location.pathname === '/' ? 'active' : ''}>
                            <Link to="/" onClick={closeMenu}>{homeTitleText}</Link>
                        </li>

                        {aboutIsEnabled && (
                            <li className={location.pathname === '/about' ? 'active' : ''}>
                                <Link to="/about" onClick={closeMenu}>{aboutTitleText}</Link>
                            </li>
                        )}

                        {contactIsEnabled && (
                            <li className={location.pathname === '/contact' ? 'active' : ''}>
                                <Link to="/contact" onClick={closeMenu}>{contactTitleText}</Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
